import React from 'react';
import { Switch, Route } from "react-router-dom";

import { MainPage } from './MainPage';
import { DashLoginPage } from './LoginPage';
import { LoginPage } from '@patternfly/react-core';

export class AppUI extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Switch>
                <Route path="/login" component={DashLoginPage} />
                <Route component={MainPage}/>
            </Switch>);
    }
}