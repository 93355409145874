import React from 'react';
import { PageSection, TextContent, Text, TextVariants, DataList, DataListItem, DataListItemRow, DataListItemCells, DataListCell, DataListAction, Button, Modal, TextInput, ModalVariant, DataListToggle, DataListContent, Form, FormGroup, Checkbox, ActionGroup } from '@patternfly/react-core';
import { GlobalContext } from '@app/GlobalContext';
import { SensorInfo } from '@app/RestAPI';
import { string } from 'prop-types';

type SensorSettingsState = {
}

type SensorSettingsProps = {
    onDelete : (id: string) => void,
    onUpdate : (id: string) => void,
    onIdentify : (id: string) => void,
    onResetID : (id: string) => void,
    onSave : (id: string, name: string, active: boolean) => void,
    onToggle : (id: string) => void,
    onNameChange : (id: string, value: string) => void,
    onActiveChange : (id: string, value: boolean) => void,
    sensor : SensorInfo,
    active : boolean,
    isExpanded : boolean
}

export class SensorSettings extends React.Component<SensorSettingsProps, SensorSettingsState> {
    static contextType = GlobalContext;
    context!: React.ContextType<typeof GlobalContext>;

    public readonly state: Readonly<SensorSettingsState> = {}

    render() {
        const { sensor, isExpanded, active } = this.props;
        const name = this.props.sensor.name;

        return (
            <DataListItem aria-labelledby="" isExpanded={isExpanded}>
                <DataListItemRow>
                    <DataListToggle
                        onClick={() => this.props.onToggle(sensor.sensorID)}
                        isExpanded={isExpanded}

                        id="expand-settings"
                        aria-controls="expand-settings"
                    />
                    <DataListItemCells
                    dataListCells={[
                        <DataListCell>{sensor.sensorID}</DataListCell>,
                        <DataListCell>{sensor.name}</DataListCell>,
                        <DataListCell>{sensor.version}</DataListCell>,
                        <DataListCell>{sensor.connected.toString()}</DataListCell>,
                    ]}
                    />
                </DataListItemRow>
                <DataListContent
                    aria-label="Sensor Settings"
                    id="expand-settings"
                    isHidden={!isExpanded}
                >
                    <Form>
                        <FormGroup label="Name" isRequired helperText="Name des Sensors" fieldId="name">
                        <TextInput
                            isRequired
                            type="text"
                            value={name}
                            onChange={(value) => this.props.onNameChange(sensor.sensorID, value)}
                        />
                        </FormGroup>

                        <FormGroup fieldId="active">
                            <Checkbox label="Aktiviert" id="chk-active" isChecked={active} onChange={(value) => this.props.onActiveChange(sensor.sensorID, value)} />
                        </FormGroup>

                        <ActionGroup>
                            <Button variant="primary" onClick={() => this.props.onSave(this.props.sensor.sensorID,
                                name, active)}>Speichern</Button>
                            <Button variant="link" onClick={() => this.props.onUpdate(
                                this.props.sensor.sensorID)}>OTA Update</Button>
                            <Button variant="link"onClick={() => this.props.onIdentify(
                                this.props.sensor.sensorID)}>Identifizieren</Button>
                            <Button variant="link"onClick={() => this.props.onResetID(
                                this.props.sensor.sensorID)}>ID zurücksetzen</Button>
                            <Button variant="danger"onClick={() => this.props.onDelete(
                                this.props.sensor.sensorID)}>Löschen</Button>
                        </ActionGroup>
                    </Form>
                </DataListContent>
            </DataListItem>
        );
    }
}