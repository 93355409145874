import React from 'react';
import { History } from 'history';
import { RestAPI, LoginType, UserInfo } from './RestAPI';

export type GlobalContextProps = { 
    user: UserInfo | undefined;
    history: History | undefined;
    update: (() => void) | undefined;
    api: RestAPI | undefined;
    onSocketMessage: ((type: string, data: any) => void) | undefined;
};

export const GlobalContext = React.createContext<GlobalContextProps>({
    user: undefined,
    history: undefined,
    update: undefined,
    api: undefined,
    onSocketMessage: undefined
});