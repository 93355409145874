export enum LoginType {
    invalid = "invalid",
    user = "user"
}

export type UserInfo = { 
    loginType: LoginType;
    name: string;
};

export class TimeSeriesEntry {
    constructor (json) {
        this.value = json.value;
        this.time = new Date(json.time);
    }

    time: Date;
    value: number;
}

export type TimeSeriesInfo = {
    unit: string,
    label: string
}

export class SensorInfo {
    constructor (json) {
        this.sensorID = json.sensorID;
        this.name = json.name;
        this.version = json.version;
        this.connected = new Date(json.connected);
    }

    version : string;
    sensorID : string;
    name : string;
    connected : Date;
}

export class SensorStatus {
    constructor (json) {
        this.sensorID = json.sensorID;
        this.measurements = new Map<string, TimeSeriesEntry>();
        for (let key in json.measurements)
            this.measurements.set(key, new TimeSeriesEntry(json.measurements[key]));
    }

    sensorID : string;
    measurements : Map<string, TimeSeriesEntry>;
}

export class RestAPI {
    get apiBase() {
        return "api/";
    }

    request<T>(uri, options): Promise<T> {
        return fetch(uri, options)
            .then((response) => {
                return new Promise<T>((resolve, reject) => {
                    if (response.ok) {
                        var lenStr = response.headers.get("Content-Length");
                        if (lenStr && parseInt(lenStr, 10) == 0) {
                            resolve();
                        }
                        else {
                            response.json().then((result) => {
                                resolve(result);
                            }).catch((error) => {
                                var err = {type: "JSON_ERROR",
                                    msg: "Invalid JSON data",
                                    exception: error};
                                reject(err);
                            });
                        }
                    }
                    else {
                        var err = {type: "HTTP_ERROR",
                            msg: "Server Error: " + response.status + " " + response.statusText,
                            status: response.status,
                            exception: new Error("Http request failed")};
                        reject(err);
                    }
                });
            });
    }

    get<T = unknown>(uri) {
        return this.request<T>(uri, {});
    }

    post<T = unknown>(uri, data) {
        return this.request<T>(uri, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify(data)
        });
    }

    getUser() {
        var uri = this.apiBase + 'user';
        return this.get<UserInfo>(uri);
    }

    login(user: string, password: string) {
        var uri = this.apiBase + 'login';
        return this.post<boolean>(uri, {user: user, password: password});
    }

    logout() {
        var uri = this.apiBase + 'logout';
        return this.post<boolean>(uri, {});
    }

    getSensorsPending() {
        return this.get<any[]>(this.apiBase + 'sensors/pending').then((json) => {
            return json.map<SensorInfo>((value) => new SensorInfo(value));
        });
    }

    getSensorsStatus() {
        return this.get<any[]>(this.apiBase + 'sensors/status').then((json) => {
            return json.map<SensorStatus>((value) => new SensorStatus(value));
        });
    }   

    getSensors() {
        return this.get<any[]>(this.apiBase + 'sensors').then((json) => {
            return json.map<SensorInfo>((value) => new SensorInfo(value));
        });
    }


    sensorUnblock(id : string) {
        return this.post(this.apiBase + 'sensor/' + id + '/unblock', {});
    }

    sensorBlock(id : string) {
        return this.post(this.apiBase + 'sensor/' + id + '/block', {});
    }

    sensorIdentify(id : string) {
        return this.post(this.apiBase + 'sensor/' + id + '/identify', {});
    }

    sensorResetID(id : string) {
        return this.post(this.apiBase + 'sensor/' + id + '/reset_id', {});
    }

    sensorDelete(id : string) {
        return this.post(this.apiBase + 'sensor/' + id + '/delete', {});
    }
    
    setSensorName(id : string, name: string) {
        return this.post(this.apiBase + 'sensor/' + id + '/info', {name: name});
    }

    sensorUpdate(id : string, url : string) {
        return this.post(this.apiBase + 'sensor/' + id + '/update', {url : url})
    }

    getSensorMeasurements(sensorID : string, type : string, from : Date, to : Date, group : number, mode : string) {
        var uri = this.apiBase + 'sensor/' + sensorID + '/measurements?type=' + type + '&from=' + from.toISOString() + '&to=' + to.toISOString() + '&group=' + group + '&mode=' + mode;
        return this.get<TimeSeriesEntry[]>(uri);
    }

    getSensorVariables(sensorID : string, from : Date, to : Date, group : number, mode : string) {
        var uri = this.apiBase + 'sensor/' + sensorID + '/variables?from=' + from.toISOString() + '&to=' + to.toISOString() + '&group=' + group + '&mode=' + mode;
        return this.get<string[]>(uri);
    }
}