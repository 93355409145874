import React from 'react';
import { PageSection, TextContent, GalleryItem, Gallery } from '@patternfly/react-core';
import { GlobalContext } from '@app/GlobalContext';
import { SensorStatus, SensorInfo, TimeSeriesEntry } from '@app/RestAPI';
import { StatusCard } from './StatusCard';
import { string } from 'prop-types';
import { SensorSettings } from './SensorSettings';

type SensorData = {
    name: string,
    status: SensorStatus,
    identify: boolean,
    identifyStart?: Date
}

type Measurement = {
    sensorID : string,
    time: string,
    value: number,
    type: string,
}

type StatusViewState = {
    _sensors : SensorData[]
}

class StateManager {
    private _sensors: Map<string, SensorData>;

    constructor() {
        this._sensors = new Map<string, SensorData>();
    }

    reload(info : SensorInfo[], status: SensorStatus[]) {
        this._sensors.clear();

        let nameMap = new Map<string, string>();
        for (const entry of info)
        {
            nameMap.set(entry.sensorID, entry.name);
        }

        for (const entry of status) {
            if (entry.measurements.size != 0)
            {
                let mname = nameMap.has(entry.sensorID) ? nameMap.get(entry.sensorID)! : "";
                let sdata : SensorData = {name: mname, status: entry, identify: false}
                this._sensors.set(entry.sensorID, sdata);
            }
        }
    }

    /**
     * Return false if a full reload is needed because a new sensor appeared.
     */
    addMeasurements(data: Measurement[]) {
        for (const entry of data)
        {
            if (!this._sensors.has(entry.sensorID))
            {
                // a new sensor appeared
                return false;
            }
            else
            {
                let sensor = this._sensors.get(entry.sensorID)!;
                let tse : TimeSeriesEntry = {time: new Date(entry.time), value: entry.value};
                sensor.status.measurements.set(entry.type, tse);
                this._sensors.set(entry.sensorID, sensor);
            }
        }

        return true;
    }

    highlightSensor(id : string) {
        let val = this._sensors.get(id);
        if (val)
        {
            val.identify = true;
            val.identifyStart = new Date();
            this._sensors.set(id, val);
        }
    }

    sensorList() {
        let values = Array.from(this._sensors.values());
        values.sort((a, b) => b.name.localeCompare(a.name));
        return values;
    }

    /**
     * Returns true if state changed
     */
    updateTimer() : boolean {
        var now = new Date().valueOf();
        let changed = false;

        for (const key of this._sensors.keys()) { 
            let val = this._sensors.get(key)!;

            // Disable highlight after 30s
            if (val.identifyStart && now - val.identifyStart.valueOf() > 30 * 1000)
            {
                val.identify = false;
                changed = true;
                this._sensors.set(key, val);
            }

            // Remove sensor if no update in 5 Minutes
            let measurements = Array.from(val.status.measurements.values());
            if (measurements.length == 0 ||
                now - measurements[0].time.valueOf() > 5 * 60 * 1000)
            {
                this._sensors.delete(key);
                changed = true;
            }
        }

        return changed;
    }
}

export class StatusView extends React.Component<{}, StatusViewState> {
    static contextType = GlobalContext;
    context!: React.ContextType<typeof GlobalContext>;

    public readonly state: Readonly<StatusViewState> = {
        _sensors: []
    };

    private _refreshTimer?: number;
    private _stateManager: StateManager;

    constructor(props : {}) {
        super(props);
        this._stateManager = new StateManager();
    }

    render() {
        let sensors = this.state._sensors;

        let cards = sensors.map(item => {
            return (<GalleryItem>
                <StatusCard name={item.name} status={item.status} identify={item.identify} />
            </GalleryItem>);
        });

        return (
            <React.Fragment>
                <PageSection>
                    <Gallery hasGutter>
                        {cards}
                    </Gallery>
                </PageSection>
            </React.Fragment>
        );
    }

    private timerRefresh = () => {
        if (this._stateManager.updateTimer())
            this.setState({_sensors: this._stateManager.sensorList()});
    }

    public componentDidMount() {
        this._refreshTimer = window.setInterval(() => {
            this.timerRefresh();
        }, 1000);

        this.context.onSocketMessage = (type, msg) => {
            if (type == "measurement") {
                if (this._stateManager.addMeasurements(msg))
                    this.setState({_sensors: this._stateManager.sensorList()});
                else
                    this.reloadData();
            }
            else if (type == "identify") {
                this._stateManager.highlightSensor(msg);
                this.setState({_sensors: this._stateManager.sensorList()});            }
        };

        this.reloadData();
    }

    public componentWillUnmount() {
        clearInterval(this._refreshTimer);
        this.context.onSocketMessage = undefined;
    }

    private reloadData() {
        this.context.api?.getSensors().then((info : SensorInfo[]) => {
            this.context.api?.getSensorsStatus().then((status : SensorStatus[]) => {
                this._stateManager.reload(info, status);
                this.setState({_sensors: this._stateManager.sensorList()});
            });
        });
    };
}