import React from 'react';
import { PageSection, TextContent, Text, TextVariants, DataList, DataListItem, DataListItemRow, DataListItemCells, DataListCell, DataListAction, Button, Modal, TextInput, ModalVariant } from '@patternfly/react-core';
import { GlobalContext } from '@app/GlobalContext';
import { SensorInfo } from '@app/RestAPI';
import { string } from 'prop-types';
import { SensorSettings } from './SensorSettings';

type SensorSettingData = {
    sensor : SensorInfo,
    active : boolean,
    isExpanded : boolean
}

type SettingsViewState = {
    pending : Map<string, SensorSettingData>,
    active : Map<string, SensorSettingData>,
    nameInput: string,
    modalOpen: boolean,
    modalSensor: string
}

export class SettingsView extends React.Component<{}, SettingsViewState> {
    static contextType = GlobalContext;
    context!: React.ContextType<typeof GlobalContext>;

    public readonly state: Readonly<SettingsViewState> = {
        pending: new Map<string, SensorSettingData>(),
        active: new Map<string, SensorSettingData>(),
        nameInput: "",
        modalOpen: false,
        modalSensor: ""
    }

    private closeModal = () => {
        this.setState({modalOpen : false});
    };

    private onSensorDeleteRequest = id => {
        this.setState({ modalOpen : true, modalSensor: id });
    }

    private onSensorDelete = () => {
        this.context.api?.sensorDelete(this.state.modalSensor).then(() => {
            this.closeModal();
            this.reloadData();
        });
    }

    private onSensorUpdate = id => {
        this.context.api?.sensorUpdate(id, "https://klima.jpfau.org/firmware.bin").then(() => {
            alert("Update gestartet");
        });
    }

    private onSensorIdentify = id => {
        this.context.api?.sensorIdentify(id).then(() => {
            alert("Identify Request gesendet");
        });
    }

    private onSensorResetID = id => {
        this.context.api?.sensorResetID(id).then(() => {
            alert("Reset ID Request gesendet");
        });
    }

    private getSensor(id) {
        let sensor = this.state.pending.get(id);
        if (!sensor)
            sensor = this.state.active.get(id);
        return sensor;
    }

    private updateSensor(id, value) {
        if (this.state.pending.has(id))
        {
            let pending = this.state.pending;
            pending.set(id, value);
            this.setState({pending: pending});
        }
        else if (this.state.active.has(id))
        {
            let active = this.state.active;
            active.set(id, value);
            this.setState({active: active});
        }
    }

    
    // onToggle
    private onSensorToggle = (id) => {
        let sensor = this.getSensor(id);
        if (sensor)
        {
            sensor.isExpanded = !sensor.isExpanded;
            this.updateSensor(id, sensor);
        }
    }

    private onSensorNameChange = (id, value) => {
        let sensor = this.getSensor(id);
        if (sensor)
        {
            sensor.sensor.name = value;
            this.updateSensor(id, sensor);
        }
    }

    private onSensorActiveChange = (id, value) => {
        let sensor = this.getSensor(id);
        if (sensor)
        {
            sensor.active = value;
            this.updateSensor(id, sensor);
        }
    };

    private onSensorSave = (id, name, active) => {
        this.context.api?.setSensorName(id, name).then(() => {
            if (active)
            {
                this.context.api?.sensorUnblock(id).then(() => {
                    this.reloadData();
                })
            }
            else
            {
                this.context.api?.sensorBlock(id).then(() => {
                    this.reloadData();
                })
            }
        });    
    }

    render() {
        let pendingSensors = Array.from(this.state.pending.values());
        pendingSensors.sort((a, b) => b.sensor.sensorID.localeCompare(a.sensor.sensorID));
        let activeSensors = Array.from(this.state.active.values());
        activeSensors.sort((a, b) => b.sensor.sensorID.localeCompare(a.sensor.sensorID));

        let pendingList = pendingSensors.map(val => <SensorSettings sensor={val.sensor} active={val.active} isExpanded={val.isExpanded}
            onDelete={this.onSensorDeleteRequest} onIdentify={this.onSensorIdentify} onSave={this.onSensorSave}
            onUpdate={this.onSensorUpdate} onResetID={this.onSensorResetID} onToggle={this.onSensorToggle}
            onActiveChange={this.onSensorActiveChange} onNameChange={this.onSensorNameChange}/>);
        let activeList = activeSensors.map(val => <SensorSettings sensor={val.sensor} active={val.active} isExpanded={val.isExpanded}
            onDelete={this.onSensorDeleteRequest} onIdentify={this.onSensorIdentify} onSave={this.onSensorSave}
            onUpdate={this.onSensorUpdate} onResetID={this.onSensorResetID} onToggle={this.onSensorToggle}
            onActiveChange={this.onSensorActiveChange} onNameChange={this.onSensorNameChange}/>);

        let modalOpen = this.state.modalOpen;

        let modal = (
            <Modal
                variant={ModalVariant.small}
                title="Sensor und alle Daten wirklich löschen?"
                isOpen={modalOpen}
                onClose={this.closeModal}
                actions={[
                <Button key="confirm" variant="danger" onClick={this.onSensorDelete}>
                    Ja
                </Button>,
                <Button key="cancel" variant="link" onClick={this.closeModal}>
                    Nein
                </Button>
                ]}
            >
            </Modal>
        );

        return (    
            <React.Fragment>
                <PageSection>
                    <TextContent style={{paddingBottom: "2em"}}><Text component={TextVariants.h1}>Nicht freigeschaltete Sensoren</Text></TextContent>
                    <DataList aria-label="">
                        {pendingList}
                    </DataList>
                </PageSection>

                <PageSection>
                    <TextContent style={{paddingBottom: "2em"}}><Text component={TextVariants.h1}>Aktive Sensoren</Text></TextContent>
                    <DataList aria-label="">
                        {activeList}
                    </DataList>
                </PageSection>

                {modal}
            </React.Fragment>
        );
    }

    public componentDidMount() {
        this.reloadData();
    }

    private reloadData() {
        this.context.api?.getSensorsPending().then(sensors => {
            let pending = new Map<string, SensorSettingData>();
            for (const entry of sensors)
            {
                pending.set(entry.sensorID, {sensor: entry, active: false, isExpanded: false});
            }
            this.setState({pending: pending});
        });
        this.context.api?.getSensors().then(sensors => {
            let sactive = new Map<string, SensorSettingData>();
            for (const entry of sensors)
            {
                sactive.set(entry.sensorID, {sensor: entry, active: true, isExpanded: false});
            }
            this.setState({active: sactive});
        });
    };
}