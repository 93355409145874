import React from 'react';
import {
    Dropdown,
    DropdownToggle,
    DropdownItem,
    DropdownSeparator,
    KebabToggle,
    Nav,
    NavExpandable,
    NavItem,
    NavList,
    Page,
    PageHeader,
    PageSidebar,
    Toolbar,
    ToolbarGroup,
    ToolbarItem,
    PageHeaderTools,
    PageHeaderToolsGroup,
    PageHeaderToolsItem,
} from '@patternfly/react-core';

import { Route, Link, withRouter, Redirect, Switch} from "react-router-dom";
import { RouteComponentProps} from "react-router-dom";

import accessibleStyles from '@patternfly/react-styles/css/utilities/Accessibility/accessibility';
import spacingStyles from '@patternfly/react-styles/css/utilities/Spacing/spacing';
import { css } from '@patternfly/react-styles';
import { CogIcon } from '@patternfly/react-icons';
import { GlobalContext } from '@app/GlobalContext';
import { LoginType } from '@app/RestAPI';
import { StatusView } from './StatusView';
import { SensorView } from './SensorView';
import { SettingsView } from './SettingsView';

export class MainPage extends React.Component<RouteComponentProps<any>> {
    static contextType = GlobalContext;

    constructor(props: RouteComponentProps<any>) {
        super(props);
    }

    public state = {
        isDropdownOpen: false,
        isKebabDropdownOpen: false
    };

    onDropdownToggle = isDropdownOpen => {
        this.setState({ isDropdownOpen });
    };

    onDropdownSelect = event => {
        this.setState({ isDropdownOpen: !this.state.isDropdownOpen });
    };

    onKebabDropdownToggle = isKebabDropdownOpen => {
        this.setState({ isKebabDropdownOpen });
    };

    onKebabDropdownSelect = event => {
        this.setState({ isKebabDropdownOpen: !this.state.isKebabDropdownOpen });
    };

    onNavSelect = result => {
        this.setState({
            activeItem: result.itemId,
            activeGroup: result.groupId
        });
    };

    onLogout = event => {
        // is this a login or logout?
        const isLoggedin = this.context.user.loginType == LoginType.user;
        this.context.api.logout().then(() => {
            if (!isLoggedin) {
                this.context.history.push("/login");
            }
            else
            {
                this.context.api.getUser().then(info => {
                    this.context.user = info;
                    this.context.update();
                });
            }
        });
    }

    public componentDidMount() {
        this.context.api.getUser().then(info => {
            this.context.user = info;
            this.context.update();
        });
    }

    render() {
        const { isDropdownOpen, isKebabDropdownOpen } = this.state;
        const { match } = this.props;
        const { pathname } = this.props.location;

        if (this.context.user === undefined) {
            return 'Loading'; // TODO: Loading indicator
        }
        const { user } = this.context;

        var settingsNav;
        if (this.context.user.loginType == LoginType.user)
            settingsNav = (<NavItem isActive={pathname === "/settings"} title="Einstellungen"><Link to="/settings">Einstellungen</Link></NavItem>);

        const pageNav = (
            <Nav onSelect={this.onNavSelect} aria-label="Nav">
                <NavList>
                    <NavItem isActive={pathname === '/'}><Link to="/">Übersicht</Link></NavItem>
                    <NavItem isActive={pathname === "/sensor" || pathname.startsWith('/sensor/')}><Link to="/sensor">Verlauf</Link></NavItem>
                    {settingsNav}
                </NavList>
            </Nav>
        );

        var userSettings : JSX.Element[] = [];
        if (false)
            userSettings = [
                <DropdownItem><CogIcon /> Einstellungen</DropdownItem>,
                <DropdownSeparator />];

        var kebabDropdownItems : JSX.Element[] = [];
        kebabDropdownItems.push(...userSettings);
        kebabDropdownItems.push(<DropdownItem onClick={this.onLogout}>{user.loginType == LoginType.user ? "Abmelden" : "Anmelden"}</DropdownItem>);

        const headerTools = (
            <PageHeaderTools>
              <PageHeaderToolsGroup>
                <PageHeaderToolsItem>
                  <Dropdown
                    isPlain
                    position="right"
                    onSelect={this.onKebabDropdownSelect}
                    toggle={<KebabToggle onToggle={this.onKebabDropdownToggle} />}
                    isOpen={isKebabDropdownOpen}
                    dropdownItems={kebabDropdownItems}
                  />
                </PageHeaderToolsItem>
              </PageHeaderToolsGroup>
            </PageHeaderTools>
          );

        const header = (
            <PageHeader
                headerTools={headerTools}
                showNavToggle />
        );

        const sidebar = <PageSidebar nav={pageNav} />;
        const pageBreadcrumb = '';

        /**
         * Can't get stupid /sensor/id working... shitty JS crap
         */
        return (
            
            <Page sidebar = {sidebar} isManagedSidebar header={header} breadcrumb={pageBreadcrumb}>
                <Switch>
                    <Route path={`${match.path}`} exact component={StatusView} />
                    <Route path={`${match.path}sensor`} exact component={SensorView} />
                    <Route path={`${match.path}settings`} component={SettingsView} />
                </Switch>
            </Page>
        );
    }
}