export type VariableInfo = {
    label : string;
    unit : string;
}

const variables = {
    "hdc.temperature" : {label: "Temperatur", unit: "°C"},
    "hdc.humidity" : {label: "Luftfeuchte", unit: "%"},
    "bme.raw.temperature" : {label: "Temperatur (raw)", unit: "°C"},
    "bme.raw.humidity" : {label: "Luftfeuchte (raw)", unit: "%"},
    "bme.iaq" : {label: "IAQ", unit: ""},
    "bme.iaqAccuracy" : {label: "IAQ Status", unit: ""},
    "bme.temperature" : {label: "Temperatur", unit: "°C"},
    "bme.humidity" : {label: "Luftfeuchte", unit: "%"},
    "bme.pressure" : {label: "Luftdruck", unit: "Pa"},
    "bme.staticIAQ" : {label: "Statischer IAQ", unit: ""},
    "bme.co2Equivalent" : {label: "CO2 Äquivalent", unit: "%"},
    "bme.breathVOC" : {label: "Breath VOC", unit: ""},
}

export function getVariableInfo(type : string) : VariableInfo {
    if (type in variables)
        return variables[type];
    else
        return {label: type, unit: ""};
}


export function filterVariables(keys: string[]) : string[] {
    let result : string[] = [];
    if (keys.includes("bme.temperature"))
        result.push("bme.temperature");
    else if (keys.includes("hdc.temperature"))
        result.push("hdc.temperature");

    if (keys.includes("bme.humidity"))
        result.push("bme.humidity");
    else if (keys.includes("hdc.humidity"))
        result.push("hdc.humidity");

    if (keys.includes("bme.pressure"))
        result.push("bme.pressure");

    if (keys.includes("bme.iaq"))
        result.push("bme.iaq");

    if (keys.includes("bme.iaqAccuracy"))
        result.push("bme.iaqAccuracy");

    return result;
}
