import React, { Component } from 'react';
import { Form,
         FormGroup, Text,
         Card, CardHeader, CardBody } from '@patternfly/react-core';

import { SensorStatus } from '@app/RestAPI';
import { getVariableInfo, filterVariables } from '@app/Variables';


export interface StatusCardProps {
    name : string;
    status : SensorStatus;
    identify : boolean;
}

export class StatusCard extends Component<StatusCardProps> {
    constructor(props: StatusCardProps) {
        super(props);
    }

    protected renderFields() {
        let measurements = this.props.status.measurements;
        let components : JSX.Element[] = [];
        let keys = filterVariables(Array.from(measurements.keys()));
        keys.sort((a, b) => b.localeCompare(a));
        keys.forEach((key) => {
            let value = measurements.get(key);
            let info = getVariableInfo(key);
            let valueText = value!.value.toFixed(1) + " " + info.unit;
            if (key == "bme.iaq" && measurements.get("bme.iaqAccuracy")!.value == 0) {
                valueText = "kalibriere";
            }
            let label = (
                <FormGroup label={info.label} fieldId={key}>
                    <Text style={{display: "flex", justifyContent: "flex-end"}}>{valueText}</Text>
                </FormGroup>);
            components.push(label);
        });

        return components;
    }

    public render() {
        let header = (<CardHeader><h3>{this.props.name}</h3></CardHeader>);
        let style : any = {height: "100%"};
        if (this.props.identify)
            style = {height: "100%", backgroundColor: "red"};

        return (
            <Card style={style}>
                {header}
                <CardBody>
                    <Form>
                        {this.renderFields()}
                    </Form>
                </CardBody>
            </Card>
        );
    }
}
