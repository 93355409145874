import React from 'react';
import {
    LoginForm,
    LoginMainFooterBandItem,
    LoginPage,
    ListVariant
} from '@patternfly/react-core';
import { ExclamationCircleIcon } from '@patternfly/react-icons';
import { GlobalContext } from '@app/GlobalContext';


export class DashLoginPage extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
    }

    public state = {
        showHelperText: false,
        usernameValue: '',
        isValidUsername: true,
        passwordValue: '',
        isValidPassword: true,
        isRememberMeChecked: false
    };

    handleUsernameChange = value => {
        this.setState({ usernameValue: value });
    };

    handlePasswordChange = passwordValue => {
        this.setState({ passwordValue });
    };

    onRememberMeClick = () => {
        this.setState({ isRememberMeChecked: !this.state.isRememberMeChecked });
    };

    onLoginButtonClick = event => {
        event.preventDefault();
        this.context.api.login(this.state.usernameValue, this.state.passwordValue).then(result => {
            if (result) {
                this.context.api.getUser().then(info => {
                    this.context.user = info;
                    this.context.history.push("/");
                    this.context.update();
                    this.setState({ isValidUsername: true });
                    this.setState({ isValidPassword: true });
                    this.setState({ showHelperText: false });
                });
            }
            else {
                this.setState({ isValidUsername: false });
                this.setState({ isValidPassword: false });
                this.setState({ showHelperText: true });
            }
        });
    };

    render() {
        const helperText = (
            <React.Fragment>
                <ExclamationCircleIcon />&nbsp;Falsche Logindaten.
            </React.Fragment>);

        const forgotCredentials = (
            <LoginMainFooterBandItem>
                <a href="#">Benutzername oder Passwort vergessen?</a>
            </LoginMainFooterBandItem>);


        const loginForm = (
            <LoginForm
                showHelperText={this.state.showHelperText}
                helperText={helperText}
                loginButtonLabel="Einloggen"
                usernameLabel="Benutzer"
                usernameValue={this.state.usernameValue}
                onChangeUsername={this.handleUsernameChange}
                isValidUsername={this.state.isValidUsername}
                passwordLabel="Passwort"
                passwordValue={this.state.passwordValue}
                onChangePassword={this.handlePasswordChange}
                isValidPassword={this.state.isValidPassword}
                onLoginButtonClick={this.onLoginButtonClick}/>);

        return (
            <LoginPage footerListVariants={ListVariant.inline} loginTitle="Bitte loggen Sie sich ein" forgotCredentials={forgotCredentials}>
                {loginForm}
            </LoginPage>);
    }
}
