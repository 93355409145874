import React from 'react';
import { PageSection, TextContent, Text, Toolbar, ToolbarGroup, ToolbarItem, Dropdown, DropdownPosition, DropdownToggle, DropdownItem, Stack, StackItem, Split, SplitItem, FormSelect, FormSelectOption, Bullseye, Select, SelectOption, Spinner } from '@patternfly/react-core';
import { Chart, ChartVoronoiContainer, ChartAxis, ChartGroup, ChartLine, ChartThemeColor } from '@patternfly/react-charts';
import { InterpolationPropType, DomainPropType, PaddingProps, Background } from 'victory';
import { GlobalContext } from '@app/GlobalContext';
import { getVariableInfo, filterVariables } from "../Variables";
import { SensorInfo } from '@app/RestAPI';
import { CaretDownIcon } from '@patternfly/react-icons';

export interface LineDataFormatter {
    formatTooltip: (val: Datapoint) => string;
    formatXTick: (x: Date) => string;
    formatYTick: (y: number) => string;
}

enum TimeFormat {
    Hour,
    Day,
}

export class UnitFormatter implements LineDataFormatter {
    unit: string;
    format: TimeFormat;

    constructor(unit: string = "", format: TimeFormat = TimeFormat.Hour) {
        this.unit = unit;
        this.format = format;
    }

    formatTooltip = (val) => {
        let date : Date = val.x;

        let dateStr = "";
        if (this.format != TimeFormat.Hour) {
            dateStr += date.getDate().toString().padStart(2, '0');
            dateStr += "." + (date.getMonth() + 1).toString().padStart(2, '0');
            dateStr += "." + date.getFullYear().toString().padStart(4, '0') + " ";
        }
        dateStr += date.getHours().toString().padStart(2, '0');
        dateStr += ":" + date.getMinutes().toString().padStart(2, '0');
        dateStr += ":" + date.getSeconds().toString().padStart(2, '0');
    
        return val.y.toFixed(1) + " " + this.unit + '\n' + dateStr;
    }

    formatXTick = (x: Date) => {
        if (this.format == TimeFormat.Hour) {
            return x.getHours().toString().padStart(2, '0') + ":" + x.getMinutes().toString().padStart(2, '0');
        }
        else {
            return x.getDate().toString().padStart(2, '0') + "." + (x.getMonth() + 1).toString().padStart(2, '0');
        }
    }

    formatYTick = (y) => {
        return y.toFixed(1) + " " + this.unit;
    }
}

export interface LineChartProps {
    lines?: LineData[];
    xTicks?: Date[];
    yTicks?: number[];

    format?: LineDataFormatter;
    height?: number;
    padding?: PaddingProps;
}

export interface Datapoint {
    x: Date;
    y: number;
}

export interface LineData {
    points: Datapoint[];
    legend: string;

    interpolation?: InterpolationPropType;
    domain?: DomainPropType;
}

export class LineChart extends React.Component<LineChartProps> {
    constructor(props: LineChartProps) {
        super(props);
    }

    private containerRef = React.createRef<HTMLDivElement>();

    public state = {
        width: 0
    };

    handleResize = () => {
        if (this.containerRef.current)
            this.setState({ width: this.containerRef.current.clientWidth });
    };

    componentDidMount() {
        setTimeout(() => {
            this.handleResize();
            window.addEventListener('resize', this.handleResize);
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    render() {
        const { width } = this.state;
        let { lines, height, padding, format } = this.props;

        if (height === undefined)
            height = 350;

        if (padding === undefined)
            padding = {
                bottom: 75,
                left: 60,
                right: 0,
                top: 35
            };

        // Empty container FIXME
        if (!lines || lines.length == 0) {
            return (
                <div ref={this.containerRef}>
                    <Chart
                        containerComponent={<ChartVoronoiContainer />}
                        legendData={[]}
                        legendPosition="bottom-left"
                        height={height}
                        padding={padding}
                        themeColor={ChartThemeColor.multiUnordered}
                        width={width}>
                        <ChartAxis scale="time" tickValues={[0]} tickFormat={[""]} />
                        <ChartAxis dependentAxis showGrid tickValues={[0]} tickFormat={[""]} />
                    </Chart>
                </div>
            );
        }

        const legendData = lines.map(val => {
            var result = {name: val.legend};
            return result;
        });

        const linesData = lines.map(val => {
            return (<ChartLine
                domain={val.domain}
                interpolation={val.interpolation}
                data={val.points} />);
        });

        return (
            <div ref={this.containerRef}>
                <Chart
                    containerComponent={<ChartVoronoiContainer labels={value => format ? format.formatTooltip(value.datum) : value.datum} constrainToVisibleArea />}
                    legendData={legendData}
                    legendPosition="bottom-left"
                    height={height}
                    padding={padding}
                    scale={{ x: "time" }}
                    themeColor={ChartThemeColor.multiUnordered}
                    width={width}>
                    <ChartAxis scale="time" tickValues={this.props.xTicks} tickFormat={x => format ? format.formatXTick(x) : x} />
                    <ChartAxis dependentAxis showGrid tickValues={this.props.yTicks} tickFormat={y => format ? format.formatYTick(y) : y} />
                    <ChartGroup>{linesData}</ChartGroup>
                </Chart>
            </div>
        );
    }
}

export interface DurationType {
    id: number;
    name: string;
}

export interface LineChartViewProps {
    chart: LineChartProps;
    isLoading: boolean;
    onDurationChange: (value: DurationType) => void;
}

export class LineChartView extends React.Component<LineChartViewProps> {
    constructor(props: LineChartViewProps) {
        super(props);
    }

    static defaultProps = {
        isLoading: false
    }

    readonly durations = [
        { id: 0, name: 'Aktuell' },
        { id: 1, name: 'Tag' },
        { id: 2, name: 'Woche' },
        { id: 3, name: 'Monat' }
    ];

    public state = {
        selectedDuration: this.durations[0],
        durationDropdownOpen: false,
    };

    onDurationSelect = (value, e) => {
        e.preventDefault();
        this.setState({ selectedDuration: this.durations[value], durationDropdownOpen: false });
        this.props.onDurationChange(this.durations[value]);
    };

    render() {
        const { selectedDuration } = this.state;
        let isLoading = this.props.isLoading;

        const toolbar = (
            <Toolbar style = {{background : "none"}}>
                <ToolbarItem>
                    <FormSelect value={selectedDuration.id} onChange={this.onDurationSelect} aria-label="FormSelect Input">
                        {this.durations.map(project => (
                            <FormSelectOption value={project.id} label={project.name} />
                    ))}
                    </FormSelect>
                </ToolbarItem>
            </Toolbar>);

        return (
            <Stack>
                <StackItem>
                    <Split hasGutter>
                        <SplitItem isFilled></SplitItem>
                        { isLoading &&
                            <ToolbarItem>
                                <Bullseye><Spinner size="lg"/></Bullseye>
                            </ToolbarItem>
                        }
                        <SplitItem>{toolbar}</SplitItem>
                    </Split>
                </StackItem>
                <StackItem>
                    <LineChart {...this.props.chart} />
                </StackItem>
            </Stack>
        );
    }
}

export interface LineChartControllerProps {
    sensorID: string,
    subType: string
}

export class LineChartController extends React.Component<LineChartControllerProps> {
    static contextType = GlobalContext;
    context!: React.ContextType<typeof GlobalContext>;

    label: string = "";
    unit : string = "";
    duration : number = 0;

    constructor(props: LineChartControllerProps) {
        super(props);
    }

    public state : {chartData: LineChartProps} = {
        chartData: {format: new UnitFormatter()}
    };

    getTimeParams(duration: number): [Date, Date, TimeFormat, number] {
        switch (duration) {
            case 0: {
                let to = new Date();
                let from = new Date(to.getTime());
                from.setHours(from.getHours() - 1);
                return [from, to, TimeFormat.Hour, 60];
            }
        
            case 1: {
                let from = new Date();
                from.setDate(from.getDate() - 1);
                let to = new Date();
                return [from, to, TimeFormat.Hour, 600];
            }

            case 2: {
                let from = new Date();
                from.setDate(from.getDate() - 7);
                let to = new Date();
                return [from, to, TimeFormat.Day, 3600];
            }

            case 3: {
                let from = new Date();
                from.setDate(from.getDate() - 30);
                let to = new Date();
                return [from, to, TimeFormat.Day, 3 * 3600];
            }

            default:
                throw new Error('Invalid input value for getTimeParams: ' + duration);
        }
    }

    fetchData(duration: number) {
        // Clear old data & show loading indicator
        let data = this.state.chartData;
        data.lines = undefined;
        this.setState({chartData: data});

        const {sensorID, subType} = this.props;
        let [from, to, format, group] = this.getTimeParams(duration);

        this.context.api!.getSensorMeasurements(sensorID, subType, from, to, group, "mean").then(series => {
            let points = series.map(x => {
                let y: Datapoint = {x: new Date(x.time), y: x.value};
                return y;
            });

            let data = this.state.chartData;
            data.lines = [{
                legend: this.label,
                points: points,
                domain: {x: [from, to]}
            }];

            let formatter = data.format as UnitFormatter;
            formatter.format = format;
            formatter.unit = this.unit;

            if (points.length == 0) {
                data.yTicks = [0];
            }
            else {
                data.yTicks = undefined;
            }

            this.setState({chartData: data});
        });
    }

    /**
     * Load label and unit information, reload data.
     */
    reloadInfoData() {
        let info = getVariableInfo(this.props.subType);
        this.unit = info.unit;
        this.label = info.label;
        this.fetchData(this.duration);
    }

    public componentDidMount() {
        this.reloadInfoData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.sensorID !== this.props.sensorID ||
            prevProps.subType !== this.props.subType) {
            this.reloadInfoData();
        }
    }

    render() {
        const { chartData } = this.state;

        return (
            <LineChartView
                isLoading = {!chartData.lines}
                onDurationChange = {d => {
                    this.duration = d.id;
                    this.fetchData(d.id);
                }}
                chart = {chartData}
            />
        );
    }
}

type SensorViewState = {
    selected: string | undefined;
    sensors: Map<string, string>;
    variables: string[];
    isOpen: boolean;
}

export class SensorView extends React.Component<{}, SensorViewState> {
    static contextType = GlobalContext;
    context!: React.ContextType<typeof GlobalContext>;

    constructor(props) {
        super(props);
    }

    public readonly state: Readonly<SensorViewState> = {
        selected: undefined,
        sensors: new Map<string, string>(),
        variables: [],
        isOpen: false
    }

    public componentDidMount() {
        this.context.api?.getSensors().then(sensors => {
            let smap = new Map<string, string>();
            for (let sens of sensors) {
                smap.set(sens.name, sens.sensorID);
            }
            this.setState({sensors: smap});
            if (!this.state.selected) {
                if (window.location.hash) {
                    let id = window.location.hash.substr(1);
                    this.selectID(id);
                }
                else {
                    this.selectValue(sensors[0].name);
                }
            }
        });
    }

    onSelect = (event, selection, isPlaceholder) => {
        this.selectValue(selection);
    };

    onToggle = isOpen => {
        this.setState({
          isOpen
        });
    };


    public selectValue(name: string) {
        let id = this.state.sensors.get(name)!;
        this.selectID(id);
    }

    public selectID(id: string) {
        let name = "";
        for (let [key, value] of Array.from(this.state.sensors.entries())) {
            if (value === id)
                name = key;
        }

        this.setState({
            selected: name,
            isOpen: false
        });

        this.context.history?.push("#" + id);

        let now = new Date();
        let prev = new Date();
        prev.setDate(now.getDate() - 14);

        this.context.api?.getSensorVariables(id, prev, now, 60, "mean").then(vars => {
            this.setState({variables: filterVariables(vars).sort()});
        });
    }

    render() {
        const { isOpen, selected, sensors, variables} = this.state;
        const dropdownItems = Array.from(sensors.keys()).sort().map(key => {
            return (<SelectOption
                key={sensors.get(key)}
                value={key}/>);
        });

        let charts : JSX.Element[] = [];
        if (selected) {
            charts = variables.map(variable => {
                return (
                    <PageSection>
                        <LineChartController sensorID = {this.state.sensors.get(selected)!} subType = {variable}/>
                    </PageSection>
                );
            });
        }

        return (
            <React.Fragment>
                <PageSection>
                <Select
                    onToggle={this.onToggle}
                    onSelect={this.onSelect}
                    isOpen={isOpen}
                    selections={selected}>{dropdownItems}</Select>
                </PageSection>
                {charts}
            </React.Fragment>
        );
    }
}
