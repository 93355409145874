import React from 'react';
import { AppUI } from './ui/AppUI';
import { GlobalContext } from './GlobalContext';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
import { RestAPI, LoginType } from './RestAPI';


export class DashApp extends React.Component {
    constructor(props) {
        super(props);
    }

    private getBasename() {
        var path = window.location.pathname;
        return path.substring(0, path.lastIndexOf('/'));
    }

    public state = {
        globalContext: {
            user: undefined,
            history: createBrowserHistory({
                basename: this.getBasename()
            }),
            update: () => {
                this.setState(this.state);
            },
            api: new RestAPI(),
            onSocketMessage: (type, data) => {}
        }
    };

    private socket : WebSocket | undefined;

    private onSocketEvent = (e) => {
        var msg = JSON.parse(e.data);

        const onSocketMessage = this.state.globalContext.onSocketMessage;
        if (onSocketMessage) {
            onSocketMessage(msg.event, msg.data);
        }
    }

    componentDidMount() {
        var href = window.location.href;
        var dir = window.location.host; // Subdirs not supported for now...
        if (window.location.protocol == "https:")
            dir = "wss://" + dir;
        else
            dir = "ws://" + dir;

        this.socket = new WebSocket(dir + '/api/events');
        this.socket.onmessage = (e) => {
            this.onSocketEvent(e);
        };
    }

    render() {
        return (
            <GlobalContext.Provider value={this.state.globalContext}>
                <Router history={this.state.globalContext.history}>
                    <AppUI/>
                </Router>
            </GlobalContext.Provider>
        );
    }
}